import { useCallback } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";

import { PaginationParams, useToast } from "@smartrent/ui";
import {
  PaginatedResponse,
  useMutationCompat,
  useQueryCompat,
} from "@smartrent/hooks";

import { $Group } from "@/react/types";
import { instance } from "@/react/hooks/api";
import { getErrorMessage } from "@/react/lib/axios-helpers";
import {
  AxiosMutationConfig,
  createAxiosQuery,
  createAxiosMutation,
} from "@/react/hooks/react-query";
import { filterObject } from "@/react/lib/object-helpers";
import { TourConfigSettings } from "@/react/types/TourConfiguration";

import {
  convertGroupFiltersToQsParams,
  GroupFilters,
} from "@/react/common/tables/groups";

import { SelectedItems } from "@/react/hooks/infinite-multiselect/utils";

import { ProspectInformationConfiguration } from "./community_defaults/prospect_information_configs";

interface AddGroupOptions {
  group: $Group;
}

interface EditGroupOptions {
  groupId: string | number;
  group: Partial<$Group>;
}

export interface GetGroupsFilters extends PaginationParams {
  groupId?: number | string;
  marketing_name?: string;
  region_ids?: string;
  ids?: string;
  exclude?: string;
  prioritize?: string;
  tourable_groups?: "true";
  has_tour_config?: "true" | "false";
  city?: string;
  state?: string;
  zip?: string;
  country?: string;
}

const QUERY_KEY = "groups";

export const useGroupsQuery = (filters: GetGroupsFilters = {}) => {
  const options = {
    params: filterObject(
      filters,
      (value) => !([undefined, null, ""] as any[]).includes(value)
    ),
  };

  return useQueryCompat([QUERY_KEY, filters], async (_key) => {
    const response = await instance().get<PaginatedResponse<$Group>>(
      "/groups",
      options
    );

    return response.data;
  });
};

// This is used for passing into the paginated selector, which does not use react query.
export const fetchGroups = async (filters: GetGroupsFilters = {}) => {
  const options = {
    params: filters,
  };

  const response = await instance().get<PaginatedResponse<$Group>>(
    "/groups",
    options
  );

  return response.data;
};

export const fetchGroupsReportSets = async (filters: GetGroupsFilters = {}) => {
  const options = {
    params: filters,
  };

  const response = await instance().get<PaginatedResponse<$Group>>(
    "/groups/report-sets",
    options
  );

  return response.data;
};

export const useInvalidateGroupsQuery = () => {
  const queryClient = useQueryClient();

  return useCallback(
    (filters: GetGroupsFilters = {}) => {
      const options = {
        params: filters,
      };

      return queryClient.invalidateQueries([QUERY_KEY, options]);
    },
    [queryClient]
  );
};

export const useAddGroupMutation = (
  options?: AxiosMutationConfig<AddGroupOptions>
) => {
  const setToast = useToast();
  const invalidateGroupQueries = useInvalidateGroupsQuery();

  return useMutationCompat(async ({ group }: AddGroupOptions) => {
    try {
      const response = await instance().post("groups", group);

      setToast({
        status: "success",
        title: "Successfully Created Property",
        message: "The property was successfully created.",
      });

      invalidateGroupQueries();

      return response.data;
    } catch (err) {
      setToast({
        status: "error",
        title: "Unable to Create Property",
        message: getErrorMessage(err),
      });

      throw err;
    }
  }, options);
};

export const useUpdateGroupMutation = (
  options?: AxiosMutationConfig<EditGroupOptions>
) => {
  const setToast = useToast();
  const invalidateGroupQueries = useInvalidateGroupsQuery();

  return useMutationCompat(async ({ group, groupId }: EditGroupOptions) => {
    try {
      const response = await instance().patch(`groups/${groupId}`, group);

      setToast({
        status: "success",
        title: "Successfully Updated Property",
        message: "The property was successfully updated.",
      });

      invalidateGroupQueries({ groupId: Number(groupId) });

      return response;
    } catch (err) {
      setToast({
        status: "error",
        title: "Unable to Update Property",
        message: getErrorMessage(err),
      });

      throw err;
    }
  }, options);
};

interface BaseBulkSetting<TSettingType extends string, TPayload> {
  filters: GroupFilters;
  selection: SelectedItems<$Group>;
  type: TSettingType;
  settings: Partial<TPayload>;
}

export type BulkSetting =
  | BaseBulkSetting<"tours", TourConfigSettings>
  | BaseBulkSetting<
      "prospect-information-config",
      ProspectInformationConfiguration
    >;

interface BulkUpdateResult {
  updated_count: number;
}

type BulkUpdateError =
  | {
      code: "ring_conflict";
      description: string;
    }
  | {
      code: "not_found" | "image_upload_error";
      description: string;
    }
  | {
      code: "unknown_error";
      description?: string;
      field?: string;
    };

export const useBulkUpdateGroupsMutation = createAxiosMutation<
  BulkUpdateResult,
  AxiosError<BulkUpdateError>,
  BulkSetting
>(
  async ({ filters, type, settings, selection }: BulkSetting) => {
    const { data } = await instance().patch<BulkUpdateResult>(
      `/groups/settings/${type}`,
      settings,
      { params: convertGroupFiltersToQsParams(filters, {}, selection) }
    );

    return data;
  },
  {
    successToast: ({ updated_count }) => ({
      message: `Tour settings for ${updated_count} communities are being updated.`,
    }),
    errorToast: (error) => {
      const data = error.response?.data;

      switch (data?.code) {
        case "ring_conflict":
          return { message: data.description };

        default:
          return {
            message: data?.description ?? "An unknown error occurred.",
          };
      }
    },
  }
);

export const useDeleteGroupMutation = (
  options?: AxiosMutationConfig<string>
) => {
  const setToast = useToast();
  const invalidateGroupQueries = useInvalidateGroupsQuery();

  return useMutationCompat(async (groupId) => {
    try {
      const response = await instance().delete(`groups/${groupId}`);

      setToast({
        status: "success",
        title: "Successfully Deleted Property",
        message: "The property was successfully deleted.",
      });

      invalidateGroupQueries();

      return response;
    } catch (err) {
      setToast({
        status: "error",
        title: "Unable to Delete Property",
        message: getErrorMessage(err),
      });
      throw err;
    }
  }, options);
};

export interface GroupOverviewStatsResponse {
  battery_alert_count: number;
  exceptions_count: number;
  move_ins_count: number;
  move_outs_count: number;
  temperature_alert_count: number;
  work_order_count: number;
}

export const useGroupOverviewStatsQuery = createAxiosQuery(
  "group-overview-stats",
  async ({ groupId }: { groupId: string | number }) => {
    const { data } = await instance().get<GroupOverviewStatsResponse>(
      `/groups/${groupId}/overview-stats`
    );
    return data;
  }
);
